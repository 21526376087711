import React from 'react'
import { Row, Col, Button, Modal } from 'antd'
import HeaderImg from '../../images/congrats.svg'
const CongratsModal = (props) => {
  return (
    <Modal
      open={props.modalVisible}
      onCancel={props.closeModal}
      className="sb-modal sb-modal-kiosk"
      width={props.width}
      height={props.height ? props.height : ''}
      destroyOnClose
      centered
      maskStyle={{
        background: '#11427FB9',
      }}
      footer={[
        <Row justify="center" key={'modal'}>
          <Col span={24}>
            <Button
              className="btn-sb btn-sb-primary w-100"
              onClick={() => {
                props.onClick()
              }}
            >
              {props.btnText}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div
        style={{
          height: '220px',
          paddingTop: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
        }}
      >
        <div style={{ position: 'absolute', top: '-78px', left: '115px' }}>
          <img src={HeaderImg} alt="img" style={{ width: '180px' }} />
        </div>
        <div style={{ fontSize: 22, textAlign: 'center' }}>{props.children}</div>
      </div>
    </Modal>
  )
}

export default CongratsModal
