import React, { useState } from 'react'
import { Row, Col, Button, Form, Input, Switch, notification, Spin } from 'antd'
import { isTablet } from 'react-device-detect'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import '../../styles/global-version.scss'
import KioskInfoModal from '../../components/kiosk/info-modal'
import CongratsModal from '../../components/kiosk/modal-congratulations'
import client from '../../api/client'

const KioskRegister = () => {
  const [registerFormRef] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [privacyPolicyModalVisible, setPrivacyPolicyModalVisible] = useState(false)
  const [congratsModalVisible, setCongratsModalVisible] = useState(false)

  const formatPhone = (number) => {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)

    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)

    let ret = ''
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
    else if (cleaned.length > 0) ret = `(${zip}`

    registerFormRef.setFieldsValue({ phoneNumber: ret })
  }

  const registerUser = async (values) => {
    setLoading(true)
    const data = { ...values }
    data.hasAgreed = privacyPolicyAccepted
    await client
      .post('/api/accounts/registerinusa', data)
      .then(async () => {
        setCongratsModalVisible(true)
      })
      .catch((err) => {
        notification.error({
          message: err.message,
        })
      })
    setLoading(false)
  }

  if (isTablet) {
    return (
      <div>
        <Helmet title="Бүртгүүлэх | SB Express" defer={false}>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <style type="text/css">
            {`  
          html,
          body {
            position: fixed;
            overflow: hidden;
          }
        `}
          </style>
        </Helmet>
        <Spin spinning={loading}>
          <div style={{ padding: '20px', height: '100vh', overflowY: 'auto' }} className="d-flex flex-column kiosk-register-wrapper">
            <div style={{ position: 'absolute' }}>
              <Row>
                <Col style={{ position: 'relative', width: '100%' }}>
                  <Button
                    type="link"
                    className="sb-arrow-back"
                    onClick={() => {
                      navigate('/kiosk')
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} size="xs" />
                    <span>Буцах</span>
                  </Button>
                </Col>
              </Row>
            </div>
            <Row type="flex" justify="center" align="middle" style={{ padding: '30px 0' }}>
              <div style={{ maxWidth: '768px' }}>
                <div style={{ fontSize: '40px', marginBottom: '20px' }} className="text-center">
                  Шинээр бүртгүүлэх
                </div>
                <span
                  style={{
                    fontSize: '18px',
                    fontWeight: '400',
                    fontStyle: 'italic',
                    marginBottom: '20px',
                    display: 'block',
                  }}
                >
                  Та өөрийн мэдээллээ бүртгүүлснээр илгээмж явуулах болон хүлээн авах бүх хаяг мэдээллээ өөрийн аккаунт-даа хадгалах боломжтой болно.
                </span>
                <Form name="kiosk-registration" onFinish={registerUser} form={registerFormRef} layout="vertical">
                  <Row gutter={[20, 10]}>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="firstName"
                        label="Нэр"
                        rules={[
                          {
                            required: true,
                            message: 'Нэрээ оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Нэр*" style={{ height: '50px' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="lastName"
                        label="Овог"
                        rules={[
                          {
                            required: true,
                            message: 'Овог нэрээ оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Овог*" style={{ height: '50px' }} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        className="mb-3"
                        name="address"
                        label="Гэрийн хаяг"
                        rules={[
                          {
                            required: true,
                            message: 'Гэрийн хаягаа оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="Гэрийн хаяг*" style={{ height: '50px' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="phoneNumber"
                        label="Утасны дугаар"
                        rules={[
                          {
                            required: true,
                            message: 'Утасны дугаараа оруулна уу!',
                          },
                        ]}
                      >
                        <Input
                          className="sb-input sb-shadow"
                          placeholder="Утас*"
                          style={{ height: '50px' }}
                          onChange={(e) => formatPhone(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        className="mb-3"
                        name="email"
                        label="И-мэйл"
                        rules={[
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]}
                      >
                        <Input className="sb-input sb-shadow" placeholder="И-мэйл" style={{ height: '50px' }} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Switch
                        checked={privacyPolicyAccepted}
                        defaultChecked={true}
                        onChange={() => setPrivacyPolicyAccepted(!privacyPolicyAccepted)}
                      />
                      <Button type="link" style={privacyPolicyTextStyle} onClick={() => setPrivacyPolicyModalVisible(true)}>
                        Би үйлчилгээний нөхцлийг зөвшөөрч байна
                      </Button>
                    </Col>
                    <Col span={24} className="mt-2">
                      <Button
                        className="btn-sb btn-sb-primary sb-shadow w-100"
                        disabled={!privacyPolicyAccepted}
                        style={{ fontWeight: 700, height: 60, fontSize: 24 }}
                        form="kiosk-registration"
                        htmlType="submit"
                      >
                        Бүртгүүлэх
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Row>
            {privacyPolicyModalVisible && (
              <KioskInfoModal
                modalVisible={privacyPolicyModalVisible}
                closeModal={() => setPrivacyPolicyModalVisible(false)}
                width={600}
                height={500}
                btnText="Зөвшөөрч байна"
                onClick={() => {
                  setPrivacyPolicyModalVisible(false)
                  setPrivacyPolicyAccepted(true)
                }}
              >
                <div style={{ maxHeight: 500 }}>
                  <div style={{ textAlign: 'left' }}>
                    <p>Үйлчилгээ үзүүлэгч</p>
                    <p>"SB EXPRESS INC Company</p>
                    <p>USA гааль</p>
                    <p>Америк улсын гааль</p>
                    <p>MGL гааль</p>
                    <p>Монгол улсын гааль</p>
                  </div>
                  <p>
                    <b>Нэг. Ерөнхий зүйл</b>
                  </p>
                  <p>
                    Үйлчилгээний нөхцөл нь "SB EXPRESS INC" company/цаашид Үйлчилгээ үзүүлэгч гэнэ/ болон "SBEXPRESS INC" company харилцагч,
                    үйлчилүүлэгчидийн/цаашид үйлчилүүлэгч гэнэ/ хоорондох үйлчилгээтэй холбоотой харилцааг зохицуулна. Үйлчилгээний нөхцөл нь
                    үйлчлүүлэгч хүлээн зөвшөөрсөнөөр хүчин төгөлдөр болох бөгөөд үйлчилгээний нөхцөлтэй танилцаагүй тохиолдолд гарсан бүх асуудлыг
                    үйлчилгээний нөхцлийн хүрээнд шийднэ. Энэхүү үйлчилгээний нөхцөл нь "SB EXPRESS INC LLC " company-аар үйлчлүүлж буй
                    үйлчлүүлэгчдийн бүх төрлийн тээвэрлэлтэнд хамааралтай болно. Үйлчилгээ үзүүлэгч тал үйлчлүүлэгчийн хувийн мэдээллийг нууцлах
                    бөгөөд энэ нь Монгол улсын "Байгууллагын нууцын тухай" хуулиар зохицуулагдана.
                  </p>
                  <p>
                    <b>Хоёр. Үйлчилгээ үзүүлэгч талын эрх үүрэг</b>
                  </p>
                  <p>
                    Үйлчилгээ үзүүлэгч талын үндсэн үйл ажиллагаа нь онлайн болон шууд худалдан авалт хийхэд тулслах, АНУ-аас Монгол улсын хооронд
                    ачаа бараа тээвэрлэх, тээвэр зуучлалын үйлчилгээ бөгөөд тээвэрлэх гэж буй, тээвэрлэсэн бараа бүтээгдэхүүнд хамаарах АНУ-ын болон
                    Монгол улсын гаальтай холбоотой асуудалд ямар нэгэн үүрэг хариуцлага хүлээхгүй болно.
                  </p>
                  <p>
                    Үйлчилгээ үзүүлэгч тал нь өөрийн захиалсан бус үйлчлүүлэгчийн хийсэн захиалгын дагуу онлайн дэлгүүр, худалдаалагчаас шуудангаар
                    ирсэн болон савлаж багласан байдлаар хүлээж авсан илгээмжийн бараа бүтээгдэхүүний бүрэн бүтэн байдал, тоо ширхэг зэрэгт
                    нарийвчилсан хяналт тавихгүй бөгөөд үүнээс үүдэн гарах үр дагаварт хариуцлага хүлээхгүй болно. Үйлчилгээ үзүүлэгч тал нь
                    Үйлчлүүлэгчийн ачаа, бараа бүтээгдэхүүнийг тээвэрлэсэнээс хойш заагдсан хугацаанд /Агаарын тээвэр ажлын 5-10 хоног, Далайн тээвэр
                    ажлын 60-90 хоног/ Монгол улсад хүргэх үүрэгтэй. (Тээвэрлэлтийн явцад дамжин өнгөрч буй орнуудад тохиолдсон байгалын гамшиг, улс
                    төрийн тогтворгүй байдал, дайн байлдаан зэрэгээс үүдэн гарсан хугацааны өөрчлөлт, эрсдэлүүд гарсан тохиолдолд энэхүү үүрэг
                    хариуцлага хамаарахгүй болно.) Үйлчилгээ үзүүлэгч нь Үйлчлүүлэгчийг ачаа барааны байршлын мэдээлэл түүнтэй холбоотой бусад
                    мэдээллээр хангах үүрэгтэй.
                  </p>
                  <p>
                    <b>Гурав. Үйлчлүүлэгч талын эрх үүрэг</b>
                  </p>
                  <p>
                    Үйлчлүүлэгч нь АНУ-ын онлайн дэлгүүрүүдээс худалдан авалтаа Үйлчилгээ үзүүлэгчийн вэйб хуудсаар дамжуулан захиалга хийлгэсэн
                    тохиолдолд худалдан авалт хийх бараа бүтээгдхүүний мэдээллийг Үйлчилгээ үзүүлэгчийн вэйб хуудсаар өгсөн шаардлагын дагуу барааны
                    өнгө, размер, тоо ширхэгийг бичих, өөрийн холбогдох мэдээлийг үнэн зөв бүртгүүлэх үүрэгтэй. Үйлчлүүлэгч нь АНУ-ын онлайн
                    дэлгүүрүүдээс худалдан авалтаа өөрөө шууд хийсэн тохиолдолд Үйлчилгээ үзүүлэгчийн вэйб хуудас дээр байгаа зөвлөмжийн дагуу хүлээн
                    авах хаягаа бичиж Монголд хүлээн авах үүрэгтэй. Үйлчлүүлэгч нь илгээж буй ачаа, бараа бүтээгдхүүнээ АНУ-д байрлах Үйлчилгээ
                    үзүүлэгчийн ажлын байранд авчирж өгсөн тохиолдолд бараа бүтээгдэхүүний талаархи мэдээллийг үнэн зөв мэдүүлэх үүрэгтэй бөгөөд буруу
                    өгсөн мэдээллээс үүдэн гарах хариуцлагыг Үйлчлүүлэгч бүрэн хариуцах үүрэгтэй. Үйлчлүүлэгч нь Үйлчилгээ үзүүлэгчээр бараа захиалга
                    хийлгэх бол бараа захиалгын төлбөрийг 100% урьдчилан төлөх бөгөөд худалдан авалтын төлбөрийг АНУ-руу шилжүүлэхэд шаардагдах мөнгөн
                    гуйвуулгын хураамжыг нэмж төлнө. Мөнгөн гуйвуулгын хураамж нь дараахи байдлаар тооцогдоно. $1-$250 =$5, $251-$500=$10 буюу $250
                    тутамд $5 нэмэгдэх болно. Үйлчлүүлэгч тал нь тээвэрлэхийг хориглосноос бусад бүх төрлийн бараа бүтээгдэхүүнийг АНУ-аас Монгол
                    улсруу тээвэрлүүлэх эрхтэй. Үйлчлүүлэгч тал нь ачаа, барааныхаа мэдээллийг Үйлчилгээ үзүүлэгч талын Америк дахь хаягт хүргэгдсэн
                    цагаас хойш Үйлчилүүлэгчийн вэйб хуудасаар дамжуулан авах эрхтэй. Үйлчилгээ үзүүлэгч тал нь Үйлчлүүлэгчид ачаа Монголд ирсэн
                    талаар утсаар мэдэгдэл өгсөнөөс 7 хоногийн дотор ачаагаа авах үүрэгтэй.
                  </p>
                  <p>
                    <b>Дөрөв. Ачааны төлбөр тооцоо</b>
                  </p>
                  <p>
                    "SB EXPRESS INC" company агаарын ачааны тээврийн үнэ нь 1кг=$10 бөгөөд төлбөрийг Монгол дахь оффисд хийсэн тохиолдолд тухайн
                    өдрийн Монгол банкны валютын зарах ханшаар бодогдоно. 1кг-аас бага жинтэй ачааг тээвэрлэхдээ дараахи байдлаар тооцно. 1-1000гр=$10
                    Үйлчлүүлэгч тал АНУ-д байрлах Үйлчилгээ үзүүлэгчийн ажлын байранд авчирж өгсөн ачааны тээврийн төлбөрийг бэлнээр болон Монголд
                    ачаагаа хүлээж авахдаа төлөгдөх нөхцөлтэйгөөр хийж болох бөгөөд ачаагаа авахадаа төлбөрийг 100% төлж барагдуулна. Үйлчлүүлэгч
                    онлайн үйлчилгээгээр үйлчилүүлэн ирсэн ачаагаа авах тохиолдолд тээврийн төлбөрийг 100% төлнө. Агаарын ачааны тээврийн хувьд олон
                    улсын агаарын тээврийн үнэлгээний журмын дагуу тухайн ачааны бодит жин болон оворын хэмжээг харьцуулан үзээд аль илүү гарсанаар
                    тээврийн төлбөр бодогдоно. Жишээ нь: Гитар , хайрцагтай бодит жин 6кг оворын хэмжээ 44inch x 17inch x 7inch=5236:366=14.3кг Далайн
                    тээвэр - Бизнесийн бус ахуйн хэрэгцээний бараа бүтээгдэхүүний хувьд дараахь байдлаар тээврийн үнэлгээг бодож гаргана. Жишээ нь:
                    Ахуйн хэрэгцээний хувцас, чихэр, бусад ижил төрлийн бус холимог ачаа байсан тохиолдолд Бизнесийн зориулалттай, нэг төрлийн болон
                    их хэмжээний ачааны үнэлгээг Үйлчилгээ үзүүлэгчийн 70005088. 75955088 гэсэн дугаараар холбогдон далайн тээвэр хариуцсан ажилтанаас
                    авах боломжтой.
                  </p>
                  <p>
                    <b>Тав. Гаалийн бүрдүүлэлтийн тухай</b>
                  </p>
                  <p>
                    Үйлчлүүлэгчийн тээвэрлүүлсэн ачаа, бараа бүтээгдэхүүнд Монгол улсын гааль бүрдүүлэлтийн журмын дагуу зарим бараа бүтээгдэхүүнийг
                    саатуулан татвар ноогдуулах эрхтэй бөгөөд ийм тохиолдолд хүлээн авагч татварыг бүрэн хариуцана. Үйлчлүүлэгч өөрийн биеэр Монгол
                    улсын гааль дээр очиж гаалын бүрдүүлэлт хийн, ноогдсон татварыг төлж бараагаа хүлээж авна. Үйлчилгээ үзүүлэгчийн тээврийн
                    төлбөрийг 100% төлж төлбөрийн тооцоогүй гэсэн баримтыг авч гаалын бүрдүүлэлтээ хийж авна. Монгол улсын гаальд татвар бодуулахдаа
                    барааны үнийн дүн + тээврийн зардлын нийлбэр дүнгээс 15,5% татвар төлөх үүрэгтэй.
                  </p>
                  <p>
                    <b>Зургаа. Ачааны эвдрэл гэмтэл болон Даатгал</b>
                  </p>
                  <p>
                    Үйлчлүүлэгч тал нь бараа бүтээгдэхүүнээ ямар нэгэн эрсэдлээс хамгаалж даатгал хийлгэж болох бөгөөд энэ нь тухайн барааны үнийн
                    дүнгийн 30%-тай тэнцэх төлбөр байна. Үйлчилгээ үзүүлэгч тал нь зөвхөн тээвэр зуучийн компани бөгөөд даатгагдаагүй барааны эвдрэл
                    гэмтэл зэргийг хариуцахгүй болно. Онлайн дэлгүүрээс Үйлчилгээ үзүүлэгч талын АНУ-д байрлах ажлын байранд хаягаар хүргэгдэн ирсэн
                    бараа бүтээгдэхүүний эвдрэл, гэмтэл, тоо ширхэгийн бүрэн бүтэн байдлыг дэлгүүр болон бараа худалдаалагч, онлайн дэлгүүр хариуцна.
                    Үйлчлүүлэгч нь 7.1 заасны дагуу даатгал хийлгэсэн тохиолдолд гэмтсэн бараа бүтээгдэхүүний нөхөн олговорыг Үйлчилгээ үзүүлэгч
                    талаас нэхэмжилж 100% гаргуулах эрхтэй.
                  </p>
                  <p>
                    <b>Долоо. Асуудал, маргаан шийдвэрлэх</b>
                  </p>
                  <p>
                    Үйлчилгээ үзүүлэгч нь Үйлчлүүлэгчийн санал гомдлыг 70005088, 75955088 утсуудаар мөн mgl_zam_usa@yahoo.com имэйл хаягаар хүлээн авч
                    шийдвэрлэнэ. Тээвэрлэлтийн явцад дамжин өнгөрч буй орнуудад тохиолдсон байгалын гамшиг, улс төрийн тогтворгүй байдал, дайн
                    байлдаан, Монгол Улсын эрх бүхий төрийн байгууллагаас гаргасан тогтоол, хориг, захирамж зэрэгээс үүдэн гарсан тээврийн үйл
                    ажиллагааны тасалдал, хугацааны өөрчлөлт, эрсдэлүүд гарсан тохиолдолд Үйлчилгээ үзүүлэгч хариуцлага хүлээх үндэслэл болохгүй.
                    Үйлчилгээ үзүүлэгч тал өөрийн эрх үүргээ зөрчсөн, үйлчлүүлэгч талд хүндрэл учруулсан бол хөнгөлөлт урамшуулал үзүүлж маргааныг
                    шийдвэрлэж болно. Үйлчлүүлэгч нь үйлчилгээний нөхцлийг зөрчсөн тохиолдолд Үйлчилгээ үзүүлэгч тал нь үйлчлүүлэгчийн эрхийг цуцлах,
                    хоошид үйлчилгээ үзүүлэхгүй байх эрхтэй. Үйлчилгээтэй холбоотой ямарваа асуудлыг талууд тохиролцоонд хүрэх замаар шийдвэрлэх ба
                    тохиролцоонд эс хүрвэл талууд холбогдох хууль тогтоомжийн дагуу шийдвэрлүүлнэ.
                  </p>
                </div>
              </KioskInfoModal>
            )}
            {congratsModalVisible && (
              <CongratsModal
                modalVisible={congratsModalVisible}
                closeModal={() => setCongratsModalVisible(false)}
                width={400}
                height={450}
                btnText="Нэвтрэх"
                onClick={() => {
                  setCongratsModalVisible(false)
                  navigate('/kiosk')
                }}
              >
                Манай системийн хэрэглэгч болсон танд баяр хүргэе!
              </CongratsModal>
            )}
          </div>
        </Spin>
      </div>
    )
  } else {
    return <div>This is rendered only in tablet/ipad</div>
  }
}
export default KioskRegister

const privacyPolicyTextStyle = {
  color: '#11427F',
  fontSize: 20,
  fontWeight: 600,
}
